export const MsiPLPTitleAndDescription = () => import('./msi-plp-title-and-description');
export const MsiPLPContainer = () => import('./msi-plp-container');
export const MsiSortProducts = () => import('./msi-sort-products');
export const MsiCountOfProducts = () => import('./msi-count-of-products');
export const MsiProductListingNavigation = () => import('./msi-product-listing-navigation');
export const MsiLoadMoreProductsButton = () => import('./msi-load-more-products-button');
export const MsiPLPAppliedFilters = () => import('./msi-plp-applied-filters');
export const MsiDynamicProductList = () => import('./msi-dynamic-product-list');
export const MsiFacetedNavigationDesktop = () => import('./msi-faceted-navigation');
export const MsiSearchResultsPageTitle = () => import('./msi-search-results-page-title');
